@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  min-height: 100vh;
}

ul {
  display: block;
  list-style-type: disc;
  unicode-bidi: isolate;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 24px;
}

ul li::marker {
  color: #375dfb;
  width: 3px;
  height: 3px;
}

a {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
