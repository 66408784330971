.MuiDialog-root .MuiPaper-root {
  position: relative;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);
  border: 1px solid #e2e4e9;
  z-index: 11;
}

.MuiDialog-root .MuiDialog-container {
  z-index: 10;
  position: relative;
}

.upload-cv-dialog .MuiPaper-root {
  border-radius: 20px;
}

.MuiDialog-root .MuiPaper-root .MuiIconButton-root {
  position: absolute;
  border-radius: 8px;
  right: 8px;
  top: 8px;
  z-index: 999;
}

.MuiDialog-root .MuiPaper-root .MuiDialogContent-root {
  padding: 20px;
  text-align: center;
  text-align: -webkit-center;
  border-bottom: 1px solid #e2e4e9;
}

.confirm-dialog .MuiPaper-root .MuiDialogContent-root .status-pill {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-dialog .MuiPaper-root .MuiDialogContent-root p {
  color: #0a0d14;
  font-weight: 500;
  letter-spacing: -0.176px;
  margin-bottom: 4px;
}

.confirm-dialog .MuiPaper-root .MuiDialogContent-root h6 {
  color: #525866;
  font-weight: 400;
  letter-spacing: -0.084px;
}

.MuiDialog-root .MuiPaper-root .MuiDialogActions-root {
  padding: 16px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.confirm-dialog .MuiPaper-root .MuiDialogActions-root button {
  margin: 0 !important;
}

.demo-dialog .MuiPaper-root {
  position: relative;
  max-width: 600px;
  padding: 5px;
}

.demo-dialog .MuiPaper-root .MuiDialogContent-root span {
  color: #0a0d14;
  display: block;
}

.demo-dialog .MuiPaper-root .MuiDialogContent-root p {
  margin-top: 20px;
  color: #525866;
}

.demo-dialog .MuiPaper-root .MuiDialogContent-root img {
  width: 260px;
  height: 156px;
}

/* Reschedule Dialog */
.reschedule-dialog .MuiPaper-root {
  position: relative;
  max-width: 605px;
}

/* Cancel Booking Dialog */
.cancel-dialog .MuiPaper-root {
  position: relative;
  max-width: 600px;
}

/* Upload CV Dialog */
.upload-cv-dialog .upload-cv-header-box {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.upload-cv-dialog .upload-cv-header-box .upload-cv-header-left {
  padding: 10px;
  border: 1px solid #e2e4e9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.upload-cv-dialog .upload-cv-header-box .upload-cv-header-right p {
  font-weight: 500;
}

.upload-cv-dialog .upload-cv-header-box .upload-cv-header-right h6 {
  color: #525866;
}

.upload-cv-dialog .status-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.upload-cv-dialog .status-list .status-item {
  border: 1px solid #e2e4e9;
  border-radius: 12px;
  padding: 16px 16px 16px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.upload-cv-dialog .status-list .status-item .status-details-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.upload-cv-dialog .status-list .status-item .status-details-wrapper .status-info {
  color: #525866;
  font-weight: 400;
  margin: 0;
  width: 50px;
  text-align: right;
}

.upload-cv-dialog .status-list .status-item .status-details-wrapper .status-details {
  display: flex;
  align-items: center;
  gap: 4px;
}

.upload-cv-dialog .status-list .status-item.completed {
  background: linear-gradient(180deg, #d4f7e9 0%, #effaf6 100%);
}

.upload-cv-dialog .status-list .status-item.failed {
  background: linear-gradient(180deg, #f9d2da 0%, #fdedf0 100%);
}

/* Menu */
.mui-menu .MuiPaper-root,
.MuiMenu-paper {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid #e2e4e9;
  background: #fff;
  box-shadow: 0px 16px 40px -8px rgba(88, 92, 95, 0.16);
  min-width: 156px;
}

.mui-menu .MuiPaper-root ul,
.MuiMenu-paper .MuiMenu-list {
  padding: 0;
  width: 100%;
}

.mui-menu .MuiPaper-root ul li.MuiMenuItem-root,
.MuiMenu-paper .MuiMenu-list li.MuiMenuItem-root {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.mui-menu .MuiPaper-root ul li.MuiMenuItem-root:hover,
.MuiMenu-paper .MuiMenu-list li.MuiMenuItem-root:hover {
  background: #f6f8fa;
}

/* snackbar */
.MuiSnackbar-root .MuiPaper-root {
  display: flex;
  gap: 8px;
}

.MuiSnackbar-root .MuiPaper-root .MuiAlert-icon,
.MuiSnackbar-root .MuiPaper-root .MuiAlert-message,
.MuiSnackbar-root .MuiPaper-root .MuiAlert-action {
  margin: 0;
  padding: 0;
}

.MuiSnackbar-root .MuiPaper-root .MuiAlert-message h6.MuiTypography-h6 {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.MuiSnackbar-root .MuiPaper-root .MuiAlert-message h6.MuiTypography-subtitle1 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}

/* More Credit Dialog */
.more-credit-dialog .MuiPaper-root {
  position: relative;
  border-radius: 40px;
  z-index: 11;
}
