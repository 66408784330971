.calcom-atoms {
  width: 100%;
}

/*Calender connect button*/
.integration-calender-component {
  width: 100%;
}

.integration-calender-component .calcom-atoms,
.manageCalender > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > div > .calcom-atoms > div > .border-subtle > .border-b > .flex {
  z-index: 1;
  position: relative;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > .flex
  > .flex
  > button {
  min-width: 86px;
}

.calcom-atoms > .cal-connect,
.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > .flex
  > .flex
  > button {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  letter-spacing: -0.006em;
  padding: 8px;
  font-size: 14px;
  gap: 4px;
  text-align: center;
  color: white;
  background: #1e3a8a;
  position: relative;
  border: none;
  cursor: pointer;
  outline: none;
  outline-offset: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

.calcom-atoms > .cal-connect:hover,
.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > .flex
  > .flex
  > button:hover {
  background: #253ea7;
}

.calcom-atoms > .cal-connect:focus,
.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > .flex
  > .flex
  > button:focus {
  background: #303e8e;
}

.calcom-atoms > .cal-connect:disabled,
.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > .flex
  > .flex
  > button:disabled {
  background: #f6f8fa;
  color: #cdd0d5;
}

/*Calender connect button border*/
.calcom-atoms > .cal-connect::before,
.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > .flex
  > .flex
  > button::before {
  content: "";
  position: absolute;
  inset: -1px;
  background: linear-gradient(90deg, #61c0ff 0%, #ff98c9 40%, #63baba 100%);
  border-radius: 8px;
  z-index: -1;
  box-shadow: 0px 1px 2px 0px rgba(55, 93, 251, 0.08);
}

.calcom-atoms > .cal-connect:hover::before,
.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > .flex
  > .flex
  > button:hover::before {
  box-shadow: none;
}

.calcom-atoms > .cal-connect:focus::before,
.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > .flex
  > .flex
  > button:focus::before {
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #ebf1ff;
}

.calcom-atoms > .cal-connect:disabled:before {
  box-shadow: none;
  border-color: #f6f8fa;
  background: #f6f8fa;
}

.no-icon > svg,
.integration-calendar-container .multi-connect > svg {
  display: none;
}

.manage-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

/* Manage button and  multi connect button*/
.manage-container > .calcom-atoms {
  width: 50%;
}

.manage-button {
  width: 50%;
}

.integration-calendar-container .multi-connect {
  width: 50%;
  padding: 18px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  border-radius: 8px;
  border: 1px solid #e2e4e9;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(82, 88, 102, 0.06);
  /* height: 36px; */
  color: #525866;
  text-align: center;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
  justify-content: center;
}

.integration-calendar-container .multi-connect {
  width: 100%;
}

.integration-calendar-container .multi-connect:hover {
  background: #f6f8fa;
  color: #0a0d14;
  border-color: #f6f8fa;
}

.integration-calendar-container .multi-connect:focus {
  border: 1px solid #0a0d14;
  background: #fff;
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #e4e5e7;
  color: #0a0d14;
}

.integration-calendar-container .multi-connect:disabled {
  border: 1px solid #f6f8fa;
  background: #f6f8fa;
  box-shadow: none;
  color: #cdd0d5;
}

.manageCalender > .MuiDialog-container > .MuiPaper-root {
  max-width: 968px;
}

.manageCalender > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root {
  padding: 29px 26px;
}

.manageCalender > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > div > .calcom-atoms > div > .border-subtle {
  border: none;
  margin: 0;
}

.manageCalender > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > div > .calcom-atoms > div > .border-subtle > .border-b {
  border-bottom: none;
  padding: 24px 0;
}

.manageCalender > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > div > .calcom-atoms:first-child,
.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > div
  > .text-emphasis,
.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > .border-b
  > .flex
  > div
  > .text-default {
  display: none;
}

.manageCalender > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > div > .calcom-atoms > div > .border-subtle > ul {
  padding: 0;
}

.manageCalender > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > div > .calcom-atoms > div > .border-subtle > ul > div:first-child {
  margin-top: 0;
}

.manageCalender > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > div > .calcom-atoms > div > .border-subtle > ul > div {
  border: 1px solid #e2e4e9;
  border-radius: 16px;
  padding: 17px 17px 20px 12px;
}

.manageCalender > .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > div > .calcom-atoms > div > .border-subtle > ul > div > .flex {
  padding: 0;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .w-full
  > .border-t {
  border: none;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .w-full
  > .border-t
  > .text-subtle {
  padding: 32px 0 0 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #525866;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .w-full
  > .border-t
  > .space-y-4 {
  padding: 10px 0 0 0;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .w-full
  > .border-t
  > .space-y-4
  > .my-2
  > .flex {
  padding: 0;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .flex
  > .w-32
  > button {
  border: 1px solid #303e8e;
  color: #303e8e;
  border-radius: 8px;
  min-height: 32px;
  height: 32px;
  min-width: 32px;
  width: 32px;
  padding: 0 !important;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .flex
  > .w-32
  > button:hover {
  background: #ebf1ff;
  border-color: #ebf1ff;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .flex
  > .w-32
  > button:focus {
  border: 1px solid #303e8e;
  background: #fff;
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #ebf1ff;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .flex
  > .w-32
  > button:disabled {
  background: #f6f8fa;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .flex
  > .flex-col
  > .gap-x-2
  > h3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  color: #0a0d14;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .flex
  > .flex-col
  > p {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #525866;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .w-full
  > .border-t
  > .space-y-4
  > .my-2
  > .flex
  > button
  > span[data-state="checked"] {
  --tw-translate-x: 0.9rem;
  margin-top: 0;
}

.manageCalender
  > .MuiDialog-container
  > .MuiPaper-root
  > .MuiDialogContent-root
  > div
  > .calcom-atoms
  > div
  > .border-subtle
  > ul
  > div
  > .w-full
  > .border-t
  > .space-y-4
  > .my-2
  > .ml-3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  color: #0a0d14;
  margin-left: 8px;
}

.calcom-atoms > div[data-state="open"] {
  z-index: 10000;
  gap: 0;
}

.calcom-atoms > .bg-background {
  padding: 29px 21px 29px 28px;
}

.calcom-atoms > .bg-background > button {
  display: none;
}

.calcom-atoms > .bg-background > .flex > h2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  color: #0a0d14;
}

.calcom-atoms > .bg-background > .flex > p,
.calcom-atoms > .bg-background > .flex > p > span {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #525866;
}

.calcom-atoms > .bg-background > .flex > p > span {
  text-decoration: underline;
}

.calcom-atoms > .bg-background > form {
  margin-top: 26px;
}

.calcom-atoms > .bg-background > form > .space-y-4 > div > label {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
  color: #0a0d14;
  margin-bottom: 4px;
}

.calcom-atoms > .bg-background > form > .space-y-4 > div {
  margin-bottom: 24px;
}

.calcom-atoms > .bg-background > form > .space-y-4 > div > div {
  height: 32px;
}

.calcom-atoms > .bg-background > form > .space-y-4 > div > input,
.calcom-atoms > .bg-background > form > .space-y-4 > div > div > input {
  height: 32px;
  border: 1px solid #e2e4e9 !important;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
}

.calcom-atoms > .bg-background > form > .space-y-4 > div > div > input {
  border-radius: 8px 0 0 8px !important;
}

.calcom-atoms > .bg-background > form > .space-y-4 > div > input:focus,
.calcom-atoms > .bg-background > form > .space-y-4 > div > div > input:focus,
.calcom-atoms > .bg-background > form > .space-y-4 > div > div {
  outline: none;
  --tw-ring-color: #e2e4e9;
  outline-offset: 0;
  --tw-ring-offset-shadow: none;
  --tw-ring-shadow: none;
  box-shadow: none;
}

.calcom-atoms > .bg-background > form > .space-y-4 > div > div > .addon-wrapper {
  border-left: none;
}

.calcom-atoms > .bg-background > form > .space-y-4 > div > div > .addon-wrapper,
.calcom-atoms > .bg-background > form > .space-y-4 > div > div > .addon-wrapper > .min-h-9 > span > button,
.calcom-atoms > .bg-background > form > .space-y-4 > div > div > .addon-wrapper > .min-h-9 {
  min-height: 32px;
  height: 32px;
}

.calcom-atoms > .bg-background > form > .mt-5 {
  margin-top: 36px;
}

.calcom-atoms > .bg-background button[data-testid="apple-calendar-login-button"] {
  height: 32px;
  width: 53px;
  border-radius: 8px;
  border: 1px solid;
  border-image-source: linear-gradient(90deg, #61c0ff 0%, #ff98c9 40%, #63baba 100%);
  background-color: #303e8e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.006em;
  padding: 0;
  justify-content: center;
}

/* Calendar Integration */
/* Destination Calendar Settings */
.destination-calendar-settings {
  border: 0;
}

.destination-calendar-settings > div:first-child {
  display: none;
}

.destination-calendar-settings > div:nth-child(2) {
  border: 0;
}

.destination-calendar-settings > div:nth-child(2) > div {
  padding: 0;
  border: 0;
}

.destination-calendar-settings > div:nth-child(2) label {
  display: none;
}

/* Destination Calendar Settings select dropdown*/
.destination-calendar-settings span[data-testid="select-control"] > div {
  padding: 8px;
  border: 1px solid #e2e4e9;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

.destination-calendar-settings span[data-testid="select-control"] > div:hover {
  background: #f6f8fa;
  border-color: #f6f8fa;
}

.destination-calendar-settings span[data-testid="select-control"] > div:hover .text-emphasis p {
  color: #525866;
}

.destination-calendar-settings span[data-testid="select-control"] + div .scroll-bar {
  padding: 8px;
}

.destination-calendar-settings span[data-testid="select-control"] + div .scroll-bar .flex .flex {
  font-weight: 500;
}

.destination-calendar-settings span[data-testid="select-control"] + div .scroll-bar .flex.bg-default .flex,
.destination-calendar-settings span[data-testid="select-control"] + div .scroll-bar .flex.text-default .flex {
  font-weight: 400;
}

.destination-calendar-settings span[data-testid="select-control"] + div .scroll-bar > div > div:first-child,
.destination-calendar-settings span[data-testid="select-control"] + div .scroll-bar > div > div:nth-child(2) > div {
  padding: 8px;
  background-color: #fff;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.destination-calendar-settings span[data-testid="select-control"] + div .scroll-bar > div > div:first-child:hover,
.destination-calendar-settings span[data-testid="select-control"] + div .scroll-bar > div > div:nth-child(2) > div:hover {
  background-color: #f6f8fa;
}

.destination-calendar-settings span[data-testid="select-control"] > div:focus-within {
  outline-offset: 0;
  border: 1px solid #0a0d14;
  background: #fff;
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #e4e5e7;
  padding: 8px;
}

.destination-calendar-settings span[data-testid="select-control"] > div svg {
  fill: #868c98;
}

.destination-calendar-settings span[data-testid="select-control"] .text-emphasis p {
  color: #525866;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.calendar-integration-modal-container div[data-state="open"] > div {
  padding: 8px;
}

.calendar-integration-modal-container div[data-state="open"] > div > div .calcom-atoms button {
  width: 100%;
  color: #384252;
  font-weight: 400;
  padding: 8px;
  transition: background-color 0.3s;
}

.calendar-integration-modal-container div[data-state="open"] > div > div .calcom-atoms button:hover {
  color: #384252 !important;
  background-color: #f6f8fa;
}

/*Remove calendar dialog style*/
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex {
  position: relative;
}

div[role="dialog"] div[data-testid="dialog-creation"] > div.flex > div.mt-0\.5 {
  height: fit-content;
  position: absolute;
  left: 154px;
  top: 2px;
}
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex > div.w-full > h2,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex > div.w-full > p {
  text-align: center;
}
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 {
  margin-bottom: 0;
}

.calcom-atoms > .bg-background > form > .mt-5 > button[data-testid="apple-calendar-login-button"],
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"] {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.006em;
  padding: 10px;
  font-size: 14px;
  gap: 4px;
  text-align: center;
  color: white;
  background: #1e3a8a;
  position: relative;
  border: none;
  cursor: pointer;
  outline: none;
  outline-offset: 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}
.calcom-atoms > .bg-background > form > .mt-5 > button[data-testid="apple-calendar-login-button"] {
  height: 32px;
  padding: 6px;
}
.calcom-atoms > .bg-background > form > .mt-5 > button[data-testid="apple-calendar-login-button"]:hover,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"]:hover {
  background: #253ea7;
}
.calcom-atoms > .bg-background > form > .mt-5 > button[data-testid="apple-calendar-login-button"]:focus,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"]:focus {
  background: #303e8e;
}
.calcom-atoms > .bg-background > form > .mt-5 > button[data-testid="apple-calendar-login-button"]:disabled,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"]:disabled {
  background: #f6f8fa;
  color: #cdd0d5;
}
.calcom-atoms > .bg-background > form > .mt-5 > button[data-testid="apple-calendar-login-button"]::before,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"]::before {
  content: "";
  position: absolute;
  inset: -1px;
  background: linear-gradient(90deg, #61c0ff 0%, #ff98c9 40%, #63baba 100%);
  border-radius: 8px;
  z-index: -1;
  box-shadow: 0px 1px 2px 0px rgba(55, 93, 251, 0.08);
}
.calcom-atoms > .bg-background > form > .mt-5 > button[data-testid="apple-calendar-login-button"]:hover::before,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"]:hover::before {
  box-shadow: none;
}
.calcom-atoms > .bg-background > form > .mt-5 > button[data-testid="apple-calendar-login-button"]:focus::before,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"]:focus::before {
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #ebf1ff;
}
.calcom-atoms > .bg-background > form > .mt-5 > button[data-testid="apple-calendar-login-button"]:disabled:before,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"]:disabled:before {
  box-shadow: none;
  border-color: #f6f8fa;
  background: #f6f8fa;
}
.calcom-atoms > .bg-background > form > .mt-5 > button.whitespace-nowrap:nth-child(1),
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"] + button {
  border-radius: 8px;
  border: 1px solid #303e8e;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(55, 93, 251, 0.08);
  padding: 10px;
  color: #303e8e;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}
.calcom-atoms > .bg-background > form > .mt-5 > button.whitespace-nowrap:nth-child(1) {
  height: 32px;
  padding: 6px;
}
.calcom-atoms > .bg-background > form > .mt-5 > button.whitespace-nowrap:nth-child(1):hover,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"] + button:hover {
  background: #ebf1ff;
  box-shadow: none;
  color: #303e8e;
  border-color: #ebf1ff;
}
.calcom-atoms > .bg-background > form > .mt-5 > button.whitespace-nowrap:nth-child(1):focus,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"] + button:focus {
  border: 1px solid #303e8e;
  background: #fff;
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #ebf1ff;
  color: #303e8e;
  border-radius: 8px;
}
.calcom-atoms > .bg-background > form > .mt-5 > button.whitespace-nowrap:nth-child(1):disabled,
div[role="dialog"] div[data-testid="dialog-creation"] > div.flex.my-5 > button[data-testid="dialog-confirmation"] + button:disabled {
  background: #f6f8fa;
  box-shadow: none;
  color: #cdd0d5;
}
/* Availability Setting */
.availability-setting-main-container label {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.011em;
}

.availability-setting-main-container .availability-setting-cta {
  z-index: 0;
  position: relative;
}

.availability-setting-main-container .availability-setting-sub-header {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.availability-setting-main-container .availability-setting-cta > div,
.availability-setting-main-container .availability-setting-cta > div > svg,
.availability-setting-main-container .availability-setting-cta > svg.mx-3.text-muted,
.availability-setting-main-container .availability-setting-cta :nth-child(3) {
  display: none;
}

.availability-setting-main-container .availability-setting-cta button[data-state="closed"] {
  display: none;
}

.availability-setting-main-container .availability-setting-cta button[type="submit"],
.availability-setting-main-container div[role="menu"] button:nth-child(2) {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.006em;
  padding: 10px 14px;
  font-size: 14px;
  text-align: center;
  color: white;
  background: #1e3a8a;
  border-radius: 10px;
  position: relative;
  border: none;
  cursor: pointer;
  height: 38px;
  outline: none;
  outline-offset: 0;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

.availability-setting-main-container div[role="menu"] button:nth-child(2) {
  height: 34px;
}

.availability-setting-main-container .availability-setting-cta button[type="submit"]:focus-visible,
.availability-setting-main-container div[role="menu"] button:nth-child(2):focus-visible {
  --tw-ring-color: transparent;
  --tw-ring-shadow: none;
}

.availability-setting-main-container .availability-setting-cta button[type="submit"]:hover,
.availability-setting-main-container div[role="menu"] button:nth-child(2):hover {
  background: #253ea7;
}
.availability-setting-main-container .availability-setting-cta button[type="submit"]:focus,
.availability-setting-main-container div[role="menu"] button:nth-child(2):focus {
  background: #303e8e;
}
.availability-setting-main-container .availability-setting-cta button[type="submit"]:focus::before,
.availability-setting-main-container div[role="menu"] button:nth-child(2):focus::before {
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #ebf1ff;
}
.availability-setting-main-container .availability-setting-cta button[type="submit"]:hover::before,
.availability-setting-main-container div[role="menu"] button:nth-child(2):hover::before {
  box-shadow: none;
}

.availability-setting-main-container .availability-setting-cta button[type="submit"]:disabled,
.availability-setting-main-container div[role="menu"] button:nth-child(2):disabled {
  background: #f6f8fa;
  color: #cdd0d5;
}
.availability-setting-main-container .availability-setting-cta button[type="submit"]:disabled::before,
.availability-setting-main-container div[role="menu"] button:nth-child(2):disabled::before {
  box-shadow: none;
  background: #f6f8fa;
}

.availability-setting-main-container .availability-setting-cta button[type="submit"]::before,
.availability-setting-main-container div[role="menu"] button:nth-child(2)::before {
  content: "";
  position: absolute;
  inset: -1px;
  background: linear-gradient(90deg, #61c0ff 0%, #ff98c9 40%, #63baba 100%);
  border-radius: 10px;
  z-index: -1;
  box-shadow: 0px 1px 2px 0px #375dfb14;
  box-shadow: 0px 1px 2px 0px rgba(55, 93, 251, 0.08);
}

.availability-setting-main-container .text-default {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
  color: #0a0d14;
}

.availability-setting-main-container .availability-setting-form button[role="switch"],
.calendar-integration-modal-container button[role="switch"] {
  width: 28px;
  height: 16px;
  border-radius: 96px;
  border: 1px solid #cdd0d5;
  background: #e2e4e9;
  box-shadow: 0px 4px 4px 0px #0f0f100f inset;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}
.availability-setting-main-container .availability-setting-form button[role="switch"]:hover,
.calendar-integration-modal-container button[role="switch"]:hover {
  background: #cdd0d5;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

.availability-setting-main-container .availability-setting-form button[role="switch"]:focus,
.calendar-integration-modal-container button[role="switch"]:focus {
  --tw-ring-offset-width: 0;
}

.availability-setting-main-container .availability-setting-form button[data-state="checked"],
.calendar-integration-modal-container button[data-state="checked"] {
  background: linear-gradient(180deg, rgba(255, 189, 241, 0.91) 0%, rgba(99, 186, 186, 0.3003) 21%, rgba(131, 155, 255, 0.8281) 100%) !important;
  box-shadow: inset 0 4px 4px rgba(15, 15, 16, 0.12);
  border-color: #b5cdd2;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}
.availability-setting-main-container .availability-setting-form button[data-state="checked"]:hover,
.calendar-integration-modal-container button[data-state="checked"]:hover {
  background: #253ea7 !important;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

.availability-setting-main-container .availability-setting-form button[data-state="checked"] > span,
.calendar-integration-modal-container button[data-state="checked"] > span {
  background-color: #fff !important;
  box-shadow:
    0px 6px 10px rgba(22, 38, 100, 0.08),
    0px 4px 8px rgba(22, 38, 100, 0.08),
    0px 2px 4px rgba(22, 38, 100, 0.08),
    inset 0px -3px 3px #e4e5e7;
  margin-left: -4px;
  margin-top: -1px;
  width: 12px;
  height: 12px;
  position: relative;
  transform: translate(1rem);
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}
.calendar-integration-modal-container button[data-state="checked"] > span {
  margin-left: -2px;
}

.availability-setting-main-container .availability-setting-form button[data-state="checked"] > span::before,
.calendar-integration-modal-container button[data-state="checked"] > span::before {
  content: "";
  width: 4px;
  height: 4px;
  background: linear-gradient(180deg, rgba(255, 99, 221, 0.91) 0%, rgba(131, 155, 255, 0.8281) 100%) !important;
  box-shadow: 0px 2px 4px rgba(27, 28, 29, 0.04);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}
.availability-setting-main-container .availability-setting-form button[data-state="checked"]:hover > span::before,
.calendar-integration-modal-container button[data-state="checked"]:hover > span::before {
  background: #253ea7 !important;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

/* Unchecked button styles */
.availability-setting-main-container .availability-setting-form button[data-state="unchecked"] > span,
.calendar-integration-modal-container button[data-state="unchecked"] > span {
  background-color: #ffffff;
  box-shadow:
    0px 6px 10px rgba(27, 28, 29, 0.06),
    0px 2px 4px rgba(27, 28, 29, 0.02),
    inset 0px -3px 3px rgba(228, 229, 231, 0.48);
  margin-left: 2px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 12px;
  height: 12px;
  position: relative;
  border: 1px solid #ffffff;
  --tw-translate-x: 0;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

.availability-setting-main-container .availability-setting-form button[data-state="unchecked"] > span::before,
.calendar-integration-modal-container button[data-state="unchecked"] > span::before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #e2e4e9;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}
.availability-setting-main-container .availability-setting-form button[data-state="unchecked"]:hover > span::before,
.calendar-integration-modal-container button[data-state="unchecked"]:hover > span::before {
  background: #cdd0d5;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

.availability-setting-main-container .availability-setting-form .text-emphasis,
.availability-setting-main-container .availability-setting-form .text-emphasis input {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #0a0d14;
  outline: none;
}

.availability-setting-main-container .availability-setting-form .text-default.w-2 {
  display: none;
}

.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] > div:nth-child(2),
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] > div:nth-child(2),
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] > div:nth-child(2),
.availability-setting-main-container .availability-setting-form div[data-testid="Wednesday"] > div:nth-child(2),
.availability-setting-main-container .availability-setting-form div[data-testid="Thursday"] > div:nth-child(2),
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] > div:nth-child(2),
.availability-setting-main-container .availability-setting-form div[data-testid="Saturday"] > div:nth-child(2) {
  gap: 12px;
}

.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] div:nth-child(2) > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] div:nth-child(2) > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] div:nth-child(2) > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Wednesday"] div:nth-child(2) > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Thursday"] div:nth-child(2) > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] div:nth-child(2) > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Saturday"] div:nth-child(2) > div > div {
  gap: 12px;
}

.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] div:nth-child(2) > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] div:nth-child(2) > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] div:nth-child(2) > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Wednesday"] div:nth-child(2) > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Thursday"] div:nth-child(2) > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] div:nth-child(2) > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Saturday"] div:nth-child(2) > div > div > div {
  gap: 28px;
}

.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] span[data-testid="select-control"] > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] span[data-testid="select-control"] > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] span[data-testid="select-control"] > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Wednesday"] span[data-testid="select-control"] > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Thursday"] span[data-testid="select-control"] > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] span[data-testid="select-control"] > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Saturday"] span[data-testid="select-control"] > div {
  border: 1px solid #e2e4e9;
  padding: 6px 14.5px 6px 9px;
  min-width: 105px !important;
  width: 105px;
}

.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] span[data-testid="select-control"] > div:hover,
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] span[data-testid="select-control"] > div:hover,
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] span[data-testid="select-control"] > div:hover,
.availability-setting-main-container .availability-setting-form div[data-testid="Wednesday"] span[data-testid="select-control"] > div:hover,
.availability-setting-main-container .availability-setting-form div[data-testid="Thursday"] span[data-testid="select-control"] > div:hover,
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] span[data-testid="select-control"] > div:hover,
.availability-setting-main-container .availability-setting-form div[data-testid="Saturday"] span[data-testid="select-control"] > div:hover {
  background: #f6f8fa;
  border-color: #f6f8fa;
}

.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Monday"]
  span[data-testid="select-control"]
  > div:hover
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Sunday"]
  span[data-testid="select-control"]
  > div:hover
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Tuesday"]
  span[data-testid="select-control"]
  > div:hover
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Wednesday"]
  span[data-testid="select-control"]
  > div:hover
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Thursday"]
  span[data-testid="select-control"]
  > div:hover
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Friday"]
  span[data-testid="select-control"]
  > div:hover
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Saturday"]
  span[data-testid="select-control"]
  > div:hover
  .text-emphasis.placeholder\:text-muted {
  color: #525866;
}

.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Wednesday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Thursday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Saturday"] span[data-testid="select-control"] > div:focus-within {
  border: 1px solid #0a0d14;
  background: #fff;
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #e4e5e7;
}

.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Monday"]
  span[data-testid="select-control"]
  > div:focus-within
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Sunday"]
  span[data-testid="select-control"]
  > div:focus-within
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Tuesday"]
  span[data-testid="select-control"]
  > div:focus-within
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Wednesday"]
  span[data-testid="select-control"]
  > div:focus-within
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Thursday"]
  span[data-testid="select-control"]
  > div:focus-within
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Friday"]
  span[data-testid="select-control"]
  > div:focus
  .text-emphasis.placeholder\:text-muted,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Saturday"]
  span[data-testid="select-control"]
  > div:focus-within
  .text-emphasis.placeholder\:text-muted {
  color: #0a0d14;
}

.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Wednesday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Thursday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] span[data-testid="select-control"] > div:focus-within,
.availability-setting-main-container .availability-setting-form div[data-testid="Saturday"] span[data-testid="select-control"] > div:focus-within {
  --tw-ring-color: transparent;
  --tw-ring-shadow: none;
}

@media (max-width: 1279px) {
  .availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 > div > div {
    min-width: 100%;
  }
}
.availability-setting-main-container .availability-setting-form button[data-testid="add-time-availability"],
.availability-setting-main-container .availability-setting-form button[data-testid="copy-button"],
.availability-setting-main-container .availability-setting-form button[data-state="closed"] {
  outline: none;
  outline-offset: 0;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
  --tw-ring-offset-width: 0;
}

.availability-setting-main-container .availability-setting-form button[data-testid="add-time-availability"]:hover,
.availability-setting-main-container .availability-setting-form button[data-testid="copy-button"]:hover,
.availability-setting-main-container .availability-setting-form button[data-state="closed"]:hover,
.availability-setting-main-container .availability-setting-form button[data-state="delayed-open"]:hover {
  background: #f6f8fa !important;
  border-radius: 8px;
}

.availability-setting-main-container .availability-setting-form button[data-testid="add-time-availability"]:focus,
.availability-setting-main-container .availability-setting-form button[data-testid="copy-button"]:focus,
.availability-setting-main-container .availability-setting-form button[data-state="closed"]:focus,
.availability-setting-main-container .availability-setting-form button[data-testid="add-time-availability"]:focus-visible,
.availability-setting-main-container .availability-setting-form button[data-testid="copy-button"]:focus-visible,
.availability-setting-main-container .availability-setting-form button[data-state="closed"]:focus-visible {
  border-radius: 8px;
  border: 1px solid #0a0d14;
  background-color: #fff;
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #e4e5e7;
}
.availability-setting-main-container .availability-setting-form button[data-testid="add-time-availability"]:disabled,
.availability-setting-main-container .availability-setting-form button[data-testid="copy-button"]:disabled,
.availability-setting-main-container .availability-setting-form button[data-testid="closed"]:disabled {
  border-radius: 8px;
}
.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] div:nth-child(2) > div > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] div:nth-child(2) > div > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] div:nth-child(2) > div > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Wednesday"] div:nth-child(2) > div > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Thursday"] div:nth-child(2) > div > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] div:nth-child(2) > div > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Saturday"] div:nth-child(2) > div > div > div > div > div {
  margin-top: 0px;
  border-radius: 10px;
}

.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 > div > div > div {
  margin-top: 0px;
  margin-bottom: 0px;
  border-radius: 10px;
}
.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 > div > div > div .scroll-bar {
  border-radius: 10px;
  padding: 8px 2px 8px 8px;
}
.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 span[data-testid="select-control"] > div {
  border-radius: 8px;
}

.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 span[data-testid="select-control"] > div:focus-within {
  --tw-ring-color: transparent;
  --tw-ring-shadow: none;
}
.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 span[data-testid="select-control"] > div:hover {
  border-color: #e2e4e9;
  box-shadow: 1px 2px 0px rgba(228, 229, 231, 0.24);
}

.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 span[data-testid="select-control"] > div:hover {
  background: #f6f8fa;
  border-color: #f6f8fa;
  box-shadow: none;
}

.availability-setting-main-container
  .availability-setting-form
  .min-w-40
  .w-full.pr-4
  span[data-testid="select-control"]
  > div:hover
  .text-emphasis.placeholder\:text-muted {
  color: #525866;
}

.availability-setting-main-container
  .availability-setting-form
  .min-w-40
  .w-full.pr-4
  span[data-testid="select-control"]
  > div:focus-within
  .text-emphasis.placeholder\:text-muted {
  color: #0a0d14 !important;
}

.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 span[data-testid="select-control"] > div:focus-within {
  border: 1px solid #0a0d14;
  background: #fff;
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #e4e5e7;
}

.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] div:nth-child(2) > div > div > div > div > div .scroll-bar,
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] div:nth-child(2) > div > div > div > div > div .scroll-bar,
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] div:nth-child(2) > div > div > div > div > div .scroll-bar,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Wednesday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Thursday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar,
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] div:nth-child(2) > div > div > div > div > div .scroll-bar,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Saturday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar {
  border-radius: 10px;
  padding: 8px 2px 8px 8px;
}

.availability-setting-main-container .availability-setting-form div[data-testid="Monday"] div:nth-child(2) > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Sunday"] div:nth-child(2) > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Tuesday"] div:nth-child(2) > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Wednesday"] div:nth-child(2) > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Thursday"] div:nth-child(2) > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Friday"] div:nth-child(2) > div > div > div > div,
.availability-setting-main-container .availability-setting-form div[data-testid="Saturday"] div:nth-child(2) > div > div > div > div {
  width: 105px;
  height: fit-content;
}

.availability-setting-main-container .availability-setting-form > div:nth-child(1) > div {
  border-radius: 16px;
  box-shadow: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);
  border: 1px solid #e2e4e9;
}

.availability-setting-main-container .availability-setting-form > div:nth-child(1) > div > div > div {
  padding: 42px 47px;
}

.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 .scroll-bar > div {
  transition: background-color 0.3s;
  padding: 8px;
  border-radius: 8px;
}

.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 .scroll-bar > div:hover {
  background-color: #f6f8fa !important;
}

.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 .scroll-bar > div.bg-emphasis {
  background-color: #fff;
  font-weight: 500;
  transition: background-color 0.3s;
}

.availability-setting-main-container .availability-setting-form .min-w-40 .w-full.pr-4 .scroll-bar > div.bg-emphasis > div {
  align-items: center;
}

.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Monday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div:hover,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Sunday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div:hover,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Tuesday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div:hover,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Wednesday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div:hover,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Thursday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div:hover,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Friday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div:hover,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Saturday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div:hover {
  background-color: #f6f8fa !important;
}

.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Monday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Sunday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Tuesday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Wednesday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Thursday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Friday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Saturday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div {
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Monday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div.bg-emphasis,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Sunday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div.bg-emphasis,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Tuesday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div.bg-emphasis,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Wednesday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div.bg-emphasis,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Thursday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div.bg-emphasis,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Friday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div.bg-emphasis,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Saturday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div.bg-emphasis {
  background-color: #fff;
  font-weight: 500;
  transition: background-color 0.3s;
}

.availability-setting-main-container .availability-setting-form div[data-side="bottom"] > div,
.availability-setting-main-container .availability-setting-form div[data-side="top"] > div {
  width: unset !important;
  height: unset !important;
  margin-top: 0px !important;
}

.availability-setting-main-container .availability-setting-form div[data-side="bottom"] > div > div,
.availability-setting-main-container .availability-setting-form div[data-side="top"] > div > div {
  width: unset !important;
  height: unset !important;
}

.availability-setting-main-container .availability-setting-form div[data-side="bottom"] > div .space-x-2.px-2,
.availability-setting-main-container .availability-setting-form div[data-side="top"] > div .space-x-2.px-2 {
  margin-top: 8px;
}

.availability-setting-main-container .availability-setting-form input[type="checkbox"]:checked {
  background-color: #303e8e !important;
}
.availability-setting-main-container .availability-setting-form input[type="checkbox"]:disabled {
  background-color: #f6f8fa !important;
}

.availability-setting-main-container .availability-setting-form div[data-side="bottom"] > div .space-x-2.px-2 button:nth-child(1),
.availability-setting-main-container .availability-setting-form div[data-side="top"] > div .space-x-2.px-2 button:nth-child(1) {
  border-radius: 10px;
  color: #525866;
  border: 1px solid white;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s;
}

.availability-setting-main-container .availability-setting-form div[data-side="bottom"] > div .space-x-2.px-2 button:nth-child(1):hover,
.availability-setting-main-container .availability-setting-form div[data-side="top"] > div .space-x-2.px-2 button:nth-child(1):hover {
  color: #0a0d14;
  background-color: #f6f8fa;
  border-color: #f6f8fa;
}

.availability-setting-main-container .availability-setting-form div[data-side="bottom"] > div .space-x-2.px-2 button:nth-child(1):focus,
.availability-setting-main-container .availability-setting-form div[data-side="top"] > div .space-x-2.px-2 button:nth-child(1):focus {
  border: 1px solid #0a0d14;
  background: #fff;
  color: #0a0d14;
  box-shadow:
    0px 0px 0px 2px #fff,
    0px 0px 0px 4px #e4e5e7;
}

.availability-setting-main-container .availability-setting-form div[data-side="bottom"] > div .space-x-2.px-2 button:nth-child(1):disabled,
.availability-setting-main-container .availability-setting-form div[data-side="top"] > div .space-x-2.px-2 button:nth-child(1):disabled {
  background: #fff;
}
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Monday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Sunday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Tuesday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Wednesday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Thursday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Friday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div
  > div,
.availability-setting-main-container
  .availability-setting-form
  div[data-testid="Saturday"]
  div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  .scroll-bar
  > div
  > div {
  align-items: center;
}

/*Calendar connect style */
