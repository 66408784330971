.MuiPopper-root {
}

.MuiPopper-root .MuiTooltip-tooltip {
  border-radius: 8px;
  border: 1px solid #e2e4e9;
  background: #f6f8fa;
  box-shadow: 0px 16px 32px -12px rgba(88, 92, 95, 0.1);
  margin-bottom: 0 !important;
  padding: 8px;

  color: #0a0d14;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  min-width: 300px;
  min-height: 100px;
}

.onboarding-checklist-tooltip.MuiTooltip-tooltip {
  background-color: white;
  color: #000;
  border: 0;
  border-radius: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0px;
  position: relative;
}

.onboarding-checklist-tooltip.MuiTooltip-tooltip::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -0.5px;
  right: -1px;
  bottom: -1px;
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(90deg, #61c0ff 0%, #ff98c9 40%, #63baba 100%);
}
