.auma-animation::after {
  font-weight: 200;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* React Quill */
.quill {
  box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
  font-family: "Inter", sans-serif !important;
  border: 1px solid #e2e4e9;
  border-radius: 12px;
}

.quill.text-area-error {
  border-color: #df1c41;
}

.quill .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0;
}

.quill .ql-toolbar.ql-snow .ql-formats button {
  margin-right: 12px;
}

.quill .ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid #e2e4e9;
  font-family: "Inter", sans-serif;
  color: #0a0d14;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.quill .ql-toolbar.ql-snow .ql-formats button svg .ql-stroke {
  stroke: #525866;
}

.quill .ql-container.ql-snow {
  border: 0;
  min-height: 394px;
  height: 394px;
  font-family: "Inter", sans-serif;
  color: #0a0d14;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.quill ::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

.quill ::-webkit-scrollbar-track {
  background: white;
  border-radius: 50px;
}

.quill ::-webkit-scrollbar-thumb {
  background-color: #868c98;
  border-radius: 50px;
  border: 4px solid #fff;
}

.quill .ql-snow.ql-toolbar .ql-active {
  background-color: #e2e4e9;
  border-radius: 6px;
}

.quill .ql-editor.ql-blank::before {
  font-style: normal;
}

.MuiStepLabel-root {
  padding: 0px;
}
.application-job-description p strong {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.27px;
}

.application-job-description p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.application-job-description li {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.application-job-description ol {
  margin-left: 25px;
  list-style: auto;
}

/* Video JS */
.create-job-video-container,
.create-job-video-container .vjs-default-skin,
.create-job-video-container video {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  overflow: hidden;
}

.create-job-video-container .video-js .vjs-big-play-button {
  border: none;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #525866 !important;
}

.create-job-video-container .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  top: 4px;
}

.create-job-video-container .video-js .vjs-control-bar {
  height: 34px;
  border-radius: 0px 0px 25px 25px;
  border: none;
  background-color: #525866;
}
